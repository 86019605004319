<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'FourOhFour',

    metaInfo () {
      return {
        title: this.$i18n.t('title'),
      }
    },

    extends: View,

    mixins: [
      LoadSections([
        '404',
      ]),
    ],

    props: {
      id: {
        type: String,
        default: '404',
      },
    },
  }
</script>
<i18n>
{
	"en": {
		"title": "Ops! Page not found"
	},
	"it": {
		"title": "Ops! Pagina non trovata"
	}
}
</i18n>
